[data-reach-dialog-content] {
  width: fit-content;
  max-width: 95vw;
}

@media (max-width: 600px) {
  [data-reach-dialog-content] {
    padding: 1rem 0 1rem 1rem;
  }
}
