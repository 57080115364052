:root {
  --page-width: 1500px;
  --space-1: 0.0625rem;
  --space-2: 0.125rem;
  --space-4: 0.25rem;
  --space-8: 0.5rem;
  --space-12: 0.75rem;
  --space-16: 1rem;
  --space-24: 1.5rem;
  --space-32: 2rem;
  --space-40: 2.5rem;
  --space-48: 3rem;
  --space-64: 4rem;
  --space-80: 5rem;
  /* FONT */
  --sans: TeleNeoWeb, sans-serif;
  --mono: monospace;
  --text-10: 0.625rem;
  --text-12: 0.75rem;
  --text-14: 0.875rem;
  --text-16: 1rem;
  --text-20: 1.25rem;
  --text-24: 1.5rem;
  --text-32: 2rem;
  --text-40: 2.5rem;
  --text-56: 3.5rem;
  --thin: 200;
  --font-weight: 400;
  --semibold: 500;
  --bold: 700;
  --bolder: 800;
  --line-height-114: 114%;
  --line-height-120: 120%;
  --line-height-125: 125%;
  --line-height-133: 133%;
  --line-height-150: 150%;
  --line-height-160: 160%;
  /* COLORS */
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
  --grey-0: hsl(0, 0%, 94.9%);
  --grey-10: hsl(0, 0%, 89.8%);
  --grey-20: hsl(0, 0%, 80%);
  --grey-30: hsl(0, 0%, 69.8%);
  --grey-40: hsl(0, 0%, 60%);
  --grey-50: hsl(0, 0%, 49.8%);
  --grey-60: hsl(0, 0%, 40%);
  --grey-70: hsl(0, 0%, 29.8%);
  --grey-80: hsl(0, 0%, 20%);
  --grey-90: hsl(0, 0%, 9.8%);
  --grey-100: hsl(0, 0%, 0%);
  --orange-0: hsl(54.39999999999998, 100%, 93.7%);
  --orange-10: hsl(48.39999999999998, 100%, 88.8%);
  --orange-20: hsl(43.69999999999999, 100%, 84.1%);
  --orange-30: hsl(38.69999999999999, 100%, 79.6%);
  --orange-40: hsl(33.5, 100%, 75.1%);
  --orange-50: hsl(29, 100%, 70.8%);
  --orange-60: hsl(24.80000000000001, 96.5%, 66.1%);
  --orange-70: hsl(17.30000000000001, 71.4%, 56.1%);
  --orange-80: hsl(17.30000000000001, 72.3%, 39.6%);
  --orange-90: hsl(17.30000000000001, 88.8%, 31.4%);
  --orange-100: hsl(11.399999999999977, 86.6%, 26.3%);
  --red-0: hsl(353.79999999999995, 100%, 88.6%);
  --red-10: hsl(351.5, 100%, 83.3%);
  --red-20: hsl(350.4, 98.1%, 78.8%);
  --red-30: hsl(350.6, 91%, 73.9%);
  --red-40: hsl(350.4, 85.1%, 68.4%);
  --red-50: hsl(350.29999999999995, 82.8%, 63.5%);
  --red-60: hsl(351.20000000000005, 82.6%, 59.4%);
  --red-70: hsl(349.70000000000005, 69%, 50.6%);
  --red-80: hsl(348.9, 74.9%, 43.7%);
  --red-90: hsl(347.1, 84%, 36.9%);
  --red-100: hsl(345.9, 100%, 30%);
  --blue-0: hsl(190.20000000000005, 100%, 92%);
  --blue-10: hsl(196.20000000000005, 100%, 85.5%);
  --blue-20: hsl(200.89999999999998, 100%, 79.2%);
  --blue-30: hsl(206, 100%, 73.3%);
  --blue-40: hsl(210.70000000000005, 100%, 67.5%);
  --blue-50: hsl(215.60000000000002, 100%, 62%);
  --blue-60: hsl(220, 100%, 56.5%);
  --blue-70: hsl(227.39999999999998, 89%, 46.3%);
  --blue-80: hsl(236.5, 100%, 37.5%);
  --blue-90: hsl(241.10000000000002, 100%, 31.2%);
  --blue-100: hsl(249.39999999999998, 100%, 25.1%);
  --teal-0: hsl(176.10000000000002, 63.3%, 90.4%);
  --teal-10: hsl(178.89999999999998, 60%, 82.4%);
  --teal-20: hsl(180, 58.5%, 74.5%);
  --teal-30: hsl(181.20000000000005, 57.6%, 66.7%);
  --teal-40: hsl(182.10000000000002, 55.2%, 58.8%);
  --teal-50: hsl(183.60000000000002, 53.8%, 50.8%);
  --teal-60: hsl(184.79999999999995, 68.9%, 42.9%);
  --teal-70: hsl(182.89999999999998, 80.1%, 35.5%);
  --teal-80: hsl(180.5, 91.5%, 27.8%);
  --teal-90: hsl(178.89999999999998, 100%, 20.6%);
  --teal-100: hsl(176.5, 100%, 16.9%);
  --green-0: hsl(78.30000000000001, 59%, 92.4%);
  --green-10: hsl(82.5, 55.6%, 85.9%);
  --green-20: hsl(86.30000000000001, 55.3%, 79.8%);
  --green-30: hsl(90.39999999999998, 52.6%, 73.5%);
  --green-40: hsl(95.30000000000001, 50.9%, 67.3%);
  --green-50: hsl(98.80000000000001, 49.7%, 61%);
  --green-60: hsl(103.19999999999999, 48.1%, 54.7%);
  --green-70: hsl(111.5, 46.1%, 48%);
  --green-80: hsl(120, 52.4%, 41.2%);
  --green-90: hsl(127.60000000000002, 58.9%, 34.3%);
  --green-100: hsl(136.3, 65.7%, 27.5%);
  --clay-0: hsl(15.800000000000011, 42.2%, 91.2%);
  --clay-10: hsl(19.399999999999977, 46.3%, 86.9%);
  --clay-20: hsl(20.899999999999977, 49.4%, 82.9%);
  --clay-30: hsl(21.899999999999977, 48.1%, 78.8%);
  --clay-40: hsl(22.30000000000001, 48.4%, 74.9%);
  --clay-50: hsl(22.80000000000001, 48.3%, 71.2%);
  --clay-60: hsl(25.100000000000023, 46.7%, 66.9%);
  --clay-70: hsl(22.899999999999977, 35.8%, 58.4%);
  --clay-80: hsl(21.399999999999977, 27.6%, 50.2%);
  --clay-90: hsl(18.899999999999977, 26.8%, 41.8%);
  --clay-100: hsl(18.600000000000023, 24.7%, 33.3%);
  --rose-0: hsl(338.79999999999995, 48.6%, 93.1%);
  --rose-10: hsl(341.4, 46%, 87.6%);
  --rose-20: hsl(341.4, 45.7%, 82%);
  --rose-30: hsl(341.9, 43.8%, 76.3%);
  --rose-40: hsl(343.79999999999995, 42.3%, 70.8%);
  --rose-50: hsl(346, 41.2%, 65.3%);
  --rose-60: hsl(346.79999999999995, 39.8%, 59.6%);
  --rose-70: hsl(344.79999999999995, 31.5%, 49.2%);
  --rose-80: hsl(342.9, 35.4%, 38.8%);
  --rose-90: hsl(340.29999999999995, 40.3%, 28.2%);
  --rose-100: hsl(338.4, 44.6%, 22%);
  --lilac-0: hsl(307.5, 33.3%, 95.3%);
  --lilac-10: hsl(310, 27.3%, 91.4%);
  --lilac-20: hsl(310.6, 28.8%, 88.4%);
  --lilac-30: hsl(310, 30.8%, 84.7%);
  --lilac-40: hsl(300, 28.2%, 77.1%);
  --lilac-50: hsl(293.6, 30.7%, 70%);
  --lilac-60: hsl(292.9, 30.9%, 62.5%);
  --lilac-70: hsl(292.79999999999995, 24.4%, 53.3%);
  --lilac-80: hsl(293.20000000000005, 23.8%, 43.7%);
  --lilac-90: hsl(291.79999999999995, 29.1%, 34.3%);
  --lilac-100: hsl(291.1, 37%, 24.9%);
  --pigeon-0: hsl(226.20000000000005, 41.9%, 93.9%);
  --pigeon-10: hsl(227.5, 42.9%, 89%);
  --pigeon-20: hsl(227.60000000000002, 41.5%, 83.9%);
  --pigeon-30: hsl(228.60000000000002, 38.9%, 78.8%);
  --pigeon-40: hsl(230.60000000000002, 38.3%, 73.9%);
  --pigeon-50: hsl(230.79999999999995, 37.1%, 68.8%);
  --pigeon-60: hsl(232.70000000000005, 35.9%, 63.9%);
  --pigeon-70: hsl(239.10000000000002, 29.8%, 55.9%);
  --pigeon-80: hsl(244.60000000000002, 26.5%, 48%);
  --pigeon-90: hsl(251.60000000000002, 30.4%, 40%);
  --pigeon-100: hsl(257.5, 33.7%, 32%);
  --neptune-0: hsl(176, 29.4%, 90%);
  --neptune-10: hsl(180, 31.6%, 85.1%);
  --neptune-20: hsl(178.29999999999995, 34.7%, 80.2%);
  --neptune-30: hsl(180, 34.4%, 74.9%);
  --neptune-40: hsl(182.29999999999995, 34.6%, 70%);
  --neptune-50: hsl(184, 33.7%, 65.1%);
  --neptune-60: hsl(184.39999999999998, 33.3%, 60%);
  --neptune-70: hsl(182.70000000000005, 27.5%, 52.9%);
  --neptune-80: hsl(180, 25.3%, 45.7%);
  --neptune-90: hsl(178.79999999999995, 24.9%, 38.6%);
  --neptune-100: hsl(178.20000000000005, 21.2%, 31.4%);
  --olive-0: hsl(95, 33.3%, 92.9%);
  --olive-10: hsl(96, 30.3%, 87.1%);
  --olive-20: hsl(98.60000000000002, 29.8%, 81.6%);
  --olive-30: hsl(100, 29%, 75.7%);
  --olive-40: hsl(101.89999999999998, 28.1%, 70%);
  --olive-50: hsl(103.19999999999999, 27.5%, 64.3%);
  --olive-60: hsl(103.89999999999998, 26.4%, 58.4%);
  --olive-70: hsl(102.19999999999999, 22%, 48.2%);
  --olive-80: hsl(100, 24.7%, 38%);
  --olive-90: hsl(97.5, 28.2%, 27.8%);
  --olive-100: hsl(97.69999999999999, 32.7%, 21%);
  --magenta_light: hsl(329, 78%, 86%);
  --primary: hsl(329.20000000000005, 100%, 44.3%);
  --primary-hover: hsl(329.29999999999995, 95.2%, 50.6%);
  --primary-active: hsl(329.29999999999995, 100%, 39.8%);
  --focus: hsl(215.60000000000002, 100%, 62%);
  --text-color: hsl(0, 0%, 9.8%);
  --text-additional: hsl(0, 0%, 40%);
  --text-link: hsl(227.39999999999998, 89%, 46.3%);
  --text-link-hover: hsl(236.5, 100%, 37.5%);
  --text-link-active: hsl(220, 100%, 56.5%);
  --text-link-visited: hsl(227.39999999999998, 89%, 46.3%);
  --text-info: hsl(227.39999999999998, 89%, 46.3%);
  --text-disabled: hsl(0, 0%, 49.8%);
  --text-success: hsl(136.3, 65.7%, 27.5%);
  --text-error: hsl(349.70000000000005, 69%, 50.6%);
  --bg-standard: hsl(0, 0%, 100%);
  --bg-light: hsl(0, 0%, 94.9%);
  --bg-darker: hsl(0, 0%, 80%);
  --bg-overlay: hsla(0, 0%, 42.4%, 0.7);
  --bg-disabled: hsl(0, 0%, 80%);
  --bg-success: hsl(136.3, 65.7%, 27.5%);
  --bg-error: hsl(351.20000000000005, 82.6%, 59.4%);
  /* SHADOW */
  --shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  --shadow-1: 0px 4px 16px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  --shadow-2: 0px 8px 32px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  --shadow-3: 0px 12px 48px 0px rgba(0, 0, 0, 0.1), 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  --shadow-4: 0px 16px 64px 0px rgba(0, 0, 0, 0.1), 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  --shadow-5: 0px 20px 80px 0px rgba(0, 0, 0, 0.1), 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  --shadow-hover: 0px 6px 24px 0px rgba(0, 0, 0, 0.1), 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  --shadow-active: 0px 2px 8px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  --shadow-2-hover: 0px 10px 40px 0px rgba(0, 0, 0, 0.1), 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  --shadow-2-active: 0px 6px 24px 0px rgba(0, 0, 0, 0.1), 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  --shadow-3-hover: 0px 14px 56px 0px rgba(0, 0, 0, 0.1), 0px 7px 14px 0px rgba(0, 0, 0, 0.1);
  --shadow-3-active: 0px 10px 40px 0px rgba(0, 0, 0, 0.1), 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  --shadow-4-hover: 0px 18px 72px 0px rgba(0, 0, 0, 0.1), 0px 9px 18px 0px rgba(0, 0, 0, 0.1);
  --shadow-4-active: 0px 14px 56px 0px rgba(0, 0, 0, 0.1), 0px 7px 14px 0px rgba(0, 0, 0, 0.1);
  --shadow-5-hover: 0px 22px 88px 0px rgba(0, 0, 0, 0.1), 0px 11px 22px 0px rgba(0, 0, 0, 0.1);
  --shadow-5-active: 0px 18px 72px 0px rgba(0, 0, 0, 0.1), 0px 9px 18px 0px rgba(0, 0, 0, 0.1);
  --easing: cubic-bezier(0.42, 0, 0.58, 1);
  --easing-enter: cubic-bezier(0.39, 0.575, 0.565, 1);
}
