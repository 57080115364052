.shadow {
  box-shadow: var(--shadow);
}
.shadow:active {
  box-shadow: var(--shadow-active);
}
.shadow:hover {
  box-shadow: var(--shadow-hover);
}
#root .page {
  max-width: var(--page-width);
  margin: 0 auto;
}

.react-toast-notifications__container {
  /* stylelint-disable-line */
  top: 110px !important;
}

@media only screen and (max-width: 1500px) {
  /*Tablets [601px -> 1200px]*/
  body {
    --page-width: 1200px;
  }
}
@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
  .page {
    --page-width: 1200px;
    padding-left: 1em;
    padding-right: 1em;
  }
}
