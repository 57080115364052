@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-lg;
  }

  @font-face {
    font-family: TeleNeoWeb;
    font-style: normal;
    font-weight: bold;
    src: url('./Assets/fonts/TeleNeoWeb-Bold.eot'); /* IE9 Compat Modes */
    src: url('./Assets/fonts/TeleNeoWeb-Bold.woff2') format('woff2'), url('./Assets/fonts/TeleNeoWeb-Bold.woff') format('woff');
  }

  @font-face {
    font-family: TeleNeoWeb;
    font-style: italic;
    font-weight: bold;
    src: url('./Assets/fonts/TeleNeoWeb-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url('./Assets/fonts/TeleNeoWeb-BoldItalic.woff2') format('woff2'), url('./Assets/fonts/TeleNeoWeb-BoldItalic.woff') format('woff');
  }

  @font-face {
    font-family: TeleNeoWeb;
    font-style: normal;
    font-weight: 900;
    src: url('./Assets/fonts/TeleNeoWeb-ExtraBold.eot'); /* IE9 Compat Modes */
    src: url('./Assets/fonts/TeleNeoWeb-ExtraBold.woff2') format('woff2'), url('./Assets/fonts/TeleNeoWeb-ExtraBold.woff') format('woff');
  }

  @font-face {
    font-family: TeleNeoWeb;
    font-style: italic;
    font-weight: 900;
    src: url('./Assets/fonts/TeleNeoWeb-ExtraBoldItalic.eot'); /* IE9 Compat Modes */
    src: url('./Assets/fonts/TeleNeoWeb-ExtraBoldItalic.woff2') format('woff2'), url('./Assets/fonts/TeleNeoWeb-ExtraBoldItalic.woff') format('woff');
  }

  @font-face {
    font-family: TeleNeoWeb;
    font-style: normal;
    font-weight: 500;
    src: url('./Assets/fonts/TeleNeoWeb-Medium.eot'); /* IE9 Compat Modes */
    src: url('./Assets/fonts/TeleNeoWeb-Medium.woff2') format('woff2'), url('./Assets/fonts/TeleNeoWeb-Medium.woff') format('woff');
  }

  @font-face {
    font-family: TeleNeoWeb;
    font-style: italic;
    font-weight: 500;
    src: url('./Assets/fonts/TeleNeoWeb-MediumItalic.eot'); /* IE9 Compat Modes */
    src: url('./Assets/fonts/TeleNeoWeb-MediumItalic.woff2') format('woff2'), url('./Assets/fonts/TeleNeoWeb-MediumItalic.woff') format('woff');
  }

  @font-face {
    font-family: TeleNeoWeb;
    font-style: normal;
    font-weight: normal;
    src: url('./Assets/fonts/TeleNeoWeb-Regular.eot'); /* IE9 Compat Modes */
    src: url('./Assets/fonts/TeleNeoWeb-Regular.woff2') format('woff2'), url('./Assets/fonts/TeleNeoWeb-Regular.woff') format('woff');
  }

  @font-face {
    font-family: TeleNeoWeb;
    font-style: italic;
    font-weight: normal;
    src: url('./Assets/fonts/TeleNeoWeb-RegularItalic.eot'); /* IE9 Compat Modes */
    src: url('./Assets/fonts/TeleNeoWeb-RegularItalic.woff2') format('woff2'), url('./Assets/fonts/TeleNeoWeb-RegularItalic.woff') format('woff');
  }

  @font-face {
    font-family: TeleNeoWeb;
    font-style: normal;
    font-weight: 200;
    src: url('./Assets/fonts/TeleNeoWeb-Thin.eot'); /* IE9 Compat Modes */
    src: url('./Assets/fonts/TeleNeoWeb-Thin.woff2') format('woff2'), url('./Assets/fonts/TeleNeoWeb-Thin.woff') format('woff');
  }

  @font-face {
    font-family: TeleNeoWeb;
    font-style: italic;
    font-weight: 200;
    src: url('./Assets/fonts/TeleNeoWeb-ThinItalic.eot'); /* IE9 Compat Modes */
    src: url('./Assets/fonts/TeleNeoWeb-ThinItalic.woff2') format('woff2'), url('./Assets/fonts/TeleNeoWeb-ThinItalic.woff') format('woff');
  }
}
